import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reducer from './Reducers/MainReducer';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger'
import App from './Containers/App';
import registerServiceWorker from './registerServiceWorker';
import LogRocket from 'logrocket';
import { RouteRoot } from './Routes';

if(process.env.NODE_ENV === 'production'){
    LogRocket.init('qxk8zj/leabero', {
       release: process.env.REACT_APP_VERSION,
    });
}


const loggerMiddleware = createLogger();

const store = createStore(
  reducer,
  applyMiddleware(
    thunk, // lets us dispatch() functions
    loggerMiddleware, // neat middleware that logs actions
    LogRocket.reduxMiddleware() // LogRocket should be the last middleware
  ));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route path={RouteRoot} component={ App }/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();

export {store};

