import React, { Component } from 'react';
import { Nav, Navbar, MenuItem, NavItem, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { FaUser, FaHome, FaThList, FaUsers, FaThLarge, FaInfo, FaBars, FaMapMarker, FaSignInAlt, FaUserEdit, FaListUl,
  FaSignOutAlt, FaTable } from 'react-icons/fa';
import Notifications from './Notifications';
import logo from '../Static/logo2.svg';
import '../Styles/MainStyles.css';
import {RouteMyOrganizations, RouteOrganizations, RouteProfileEdit, RouteScoreKeeper, RouteVenues} from '../Routes';
import { slide as Menu } from 'react-burger-menu';
import '../Styles/SidebarMenu.css';
import Avatar from 'react-avatar';

const RightMenu = ({user, performLogout, onSelect}) => {
  if (user.authenticated){
    return (
      <Dropdown componentClass="li" id="user-dropdown" rootCloseEvent="click">
        <Dropdown.Toggle useAnchor={true}>
          <FaUser/> {user.full_name}
        </Dropdown.Toggle>
        <Dropdown.Menu onSelect={onSelect}>
          <LinkContainer to={RouteProfileEdit}><MenuItem>Edit Profile</MenuItem></LinkContainer>
          <LinkContainer to={RouteMyOrganizations}><MenuItem>Club Admin</MenuItem></LinkContainer>
          <LinkContainer to={RouteScoreKeeper}><MenuItem>ScoreKeeper</MenuItem></LinkContainer>
          <MenuItem divider />
          <MenuItem onClick={performLogout}>Log out</MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  else {
    return (
      <LinkContainer to="/login" onClick={onSelect}><NavItem><FaSignInAlt style={{verticalAlign:'text-bottom'}} size={18}/> Login/Register</NavItem></LinkContainer>
    )
  }
};

class TopMenuBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      navExpanded: false,
      windowWidth: 1000,
      sidebarOpen: false
    }
  }

  toggleNav = () => {
    this.setState({navExpanded: !this.state.navExpanded});
  };

  collapseNav = () => {
    this.setState({navExpanded: false});
  };

  // updateDimensions = () => {
  //       this.setState({windowWidth: window.innerWidth});
  // };
  //
  // componentWillMount = () => {
  //     this.updateDimensions();
  // };
  //
  // componentDidMount = () => {
  //     window.addEventListener("resize", this.updateDimensions);
  // };
  //
  // componentWillUnmount = () => {
  //     window.removeEventListener("resize", this.updateDimensions);
  // };

  toggleSidebar = () => {
    this.setState({sidebarOpen: !this.state.sidebarOpen})
  };

  updateSidebarState = (state) => {
    this.setState({sidebarOpen: state.isOpen})
  };

  render() {
    if(!this.props.mobile) {
      return (
        <Navbar inverse fluid={false} onToggle={this.toggleNav} expanded={this.state.navExpanded}>
          <Navbar.Header>
            <Navbar.Brand>
              <LinkContainer style={{cursor: 'pointer'}} to="/"><img src={logo} alt="LEABERO.COM"/></LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav onSelect={this.collapseNav}>
              <LinkContainer to="/" exact={true}><NavItem>Home</NavItem></LinkContainer>
              <LinkContainer to="/events"><NavItem>Events</NavItem></LinkContainer>
              <LinkContainer to={RouteOrganizations}><NavItem>Clubs</NavItem></LinkContainer>
              <LinkContainer to={RouteVenues}><NavItem>Venues</NavItem></LinkContainer>
              <LinkContainer to="/users"><NavItem>Users</NavItem></LinkContainer>
              <LinkContainer to="/about"><NavItem>About</NavItem></LinkContainer>
            </Nav>
            <Nav pullRight>
              <Notifications friendRequests={this.props.friendRequests}
                             notifications={[]}
                             handleAccept={this.props.friendRequestAccept}
                             handleIgnore={this.props.friendRequestIgnore}
                             onSelect={this.collapseNav}
              />
              <RightMenu user={this.props.user}
                         performLogout={this.props.performLogout}
                         onSelect={this.collapseNav}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
    else {
      return (
        <div>
          <Menu width={ 200 } isOpen={this.state.sidebarOpen} onStateChange={this.updateSidebarState}>
            {this.props.user.authenticated ?
            <div>
              <div className="text-center"><Avatar size="100" round={true} src={this.props.user.photo} name={this.props.user.first_name + ' ' + this.props.user.last_name} /></div>
              <div className="text-center"><h4>{this.props.user.first_name} {this.props.user.last_name}</h4></div>
              <Link to={RouteProfileEdit} onClick={this.toggleSidebar}><FaUserEdit size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Edit Profile</span></Link>
              <Link to={RouteMyOrganizations} onClick={this.toggleSidebar}><FaListUl size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Club Admin</span></Link>
              <Link to={RouteScoreKeeper} onClick={this.toggleSidebar}><FaTable size={18.4} style={{verticalAlign:'text-bottom'}}/><span>ScoreKeeper</span></Link>
              <hr />
            </div> :
            <div>
              <Link to="/login" onClick={this.toggleSidebar}><FaSignInAlt size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Login</span></Link>
              <hr />
            </div>}

            <Link to="/" onClick={this.toggleSidebar}><FaHome size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Home</span></Link>
            <Link to="/events" onClick={this.toggleSidebar}><FaThList size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Events</span></Link>
            <Link to={RouteOrganizations} onClick={this.toggleSidebar}><FaThLarge size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Clubs</span></Link>
            <Link to={RouteVenues} onClick={this.toggleSidebar}><FaMapMarker size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Venues</span></Link>
            <Link to="/users" onClick={this.toggleSidebar}><FaUsers size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Users</span></Link>
            <Link to="/about" onClick={this.toggleSidebar}><FaInfo size={18.4} style={{verticalAlign:'text-bottom'}}/><span>About</span></Link>

            <hr />
            {this.props.user.authenticated &&
            <Link to="/login" onClick={()=>{this.props.performLogout(); this.toggleSidebar();}}><FaSignOutAlt size={18.4} style={{verticalAlign:'text-bottom'}}/><span>Log Out</span></Link>
            }
          </Menu>
          <Navbar style={{height: "70px"}}>
            <FaBars style={{margin: "15px 15px 15px 0px", cursor:"pointer", display:"inline"}} size={35} onClick={this.toggleSidebar}/>
            <Notifications friendRequests={this.props.friendRequests}
                           notifications={[]}
                           handleAccept={this.props.friendRequestAccept}
                           handleIgnore={this.props.friendRequestIgnore}
                           onSelect={this.collapseNav}
            />
            <LinkContainer style={{cursor: 'pointer', height:"60px", margin:"5px -15px 5px 5px"}} className="pull-right" to="/">
              <img src={logo} alt="LEABERO.COM"/>
            </LinkContainer>
          </Navbar>
        </div>
      );
    }
  }
}

export default TopMenuBar;
