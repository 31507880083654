// Core Reducer - responsible for handling store['core'] object

import { PAGESTATE } from '../Actions/Core'

const defaultCoreState = {
  isGlobalFetching: false,
  updatePage: null,
  page: {},
  mobile: window.innerWidth <= 767
};

function core(state=defaultCoreState, action) {
  if(action.type==='core'){
    if('isGlobalFetching' in action) {
      state = Object.assign({}, state, {
        isGlobalFetching: action.isGlobalFetching===true
      });
      if (action.redirect) {
        this.props.history.replace(action.redirect);
      }
      if (action.follow) {
        this.props.history.push(action.follow);
      }
    }
    if('updatePage' in action){
      state = Object.assign({}, state, {
        updatePage: action.updatePage
      });
    }
    if('mobile' in action){
      state = Object.assign({}, state, {
        mobile: action.mobile
      });
    }
  }
  if(action.type===PAGESTATE){
    delete action['type'];
    state.page = Object.assign({}, state.page, action);
  }

  return state;
}

export default core
