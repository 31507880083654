import { Route, Redirect } from 'react-router-dom';
import React  from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
        return localStorage.getItem('jwt_token') === null ? (
          <Redirect
            to={{
              pathname: "/login",
              state: {from: props.location}
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    }
  />
);


export default PrivateRoute
