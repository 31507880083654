import {Request} from '../Actions/Core'
import axios from "axios/index";
import queryString from 'query-string';

const hostname = window && window.location && window.location.hostname;
let basepath = '';
if (hostname.endsWith("leabero.com")) {
  switch(hostname){
    case 'www.leabero.com':
      // prod env
      basepath = 'https://api.leabero.com';
      break;
    case 'leabero.com':
      // also prod env
      basepath = 'https://api.leabero.com';
      break;
    default:
      // envs other than prod
      basepath = `https://${hostname.split('.')[0]}-api.${hostname.substring(hostname.indexOf('.')+1)}`
  }
}
else {
  // local dev env, api is at same host as web
  basepath = '';
}


class OrgLocation {
  constructor(organization, id=null){
    this.id = id;
    this.organization = organization;
  }
  getAll = () => Request().get(`${basepath}/api/my/organizations/${this.organization}/locations/`);
  getSubset = (ids) => Request().get(`${basepath}/api/my/organizations/${this.organization}/locations/?subset=${ids.join(',')}`);
  getOne = (id) => Request().get(`${basepath}/api/my/organizations/${this.organization}/locations/${id}/`);
  updateOne = (id, object) => Request().put(`${basepath}/api/my/organizations/${this.organization}/locations/${id}/`, object);
  deleteOne = (id) => Request().delete(`${basepath}/api/my/organizations/${this.organization}/locations/${id}/`);
  createOne = (object) => Request().post(`${basepath}/api/my/organizations/${this.organization}/locations/`, object)
}

class League {
  constructor(organization, id=null){
    this.id = id;
    this.organization =organization;
  }

  getOne = (id) => Request().get(`${basepath}/api/my/organizations/${this.organization}/leagues/${id}/`);
  getAll = () => Request().get(`${basepath}/api/my/organizations/${this.organization}/leagues/`);
  updateOne = (id, object) => Request().put(`${basepath}/api/my/organizations/${this.organization}/leagues/${id}/`, object);
  deleteOne = (id) => Request().delete(`${basepath}/api/my/organizations/${this.organization}/leagues/${id}/`);
  createOne = (object) => Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/`, object);
  listLeague = (id) => Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${id}/list_league/`);
  unlistLeague = (id) => Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${id}/unlist_league/`);

  registerPlayer = (object) => Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/players/`, object);
  unregisterPlayer = (regID) => Request().delete(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/players/${regID}/`);
  addPlayerToTeam = (teamID, playerRegID) => Request().patch(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/players/${playerRegID}/`, {sports_event_team: teamID});
  removePlayerFromTeam = (playerRegID) => Request().patch(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/players/${playerRegID}/`, {sports_event_team: null});
  registerTeam = (object) => Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/teams/`, object);
  editTeam = (regID, object) => Request().put(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/teams/${regID}/`, object);
  unregisterTeam = (regID) => Request().delete(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/teams/${regID}/`);
  setTeamCaptain = (teamRegID, userID) => Request().patch(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/teams/${teamRegID}/`, {captain: userID});

  getScheduleDates = () => Request().get(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/dates/`);
  addScheduleDate = (date, name, description) => Request().post(`${basepath}/api/manage/event_dates/`, {date, name, description, sports_event: this.id});
  removeScheduleDate = (dateID) => Request().delete(`${basepath}/api/manage/event_dates/${dateID}/`);
  editScheduleDate = (id, date, name, description) => Request().patch(`${basepath}/api/manage/event_dates/${id}/`, {date, name, description});
  getMatchesForDay = (date) => Request().get(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/?event_date=${date}`);
  saveMatch = (match) => {
    if(match.id){
      return Request().put(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/${match.id}/`, match);
    } else {
      return Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/`, match);
    }
  };
  deleteMatch = (matchID) => {
    if(matchID instanceof Array){
      return Request().post(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/delete_multiple/`,{matches: matchID});
    } else {
      return Request().delete(`${basepath}/api/my/organizations/${this.organization}/leagues/${this.id}/matches/${matchID}/`);
    }
  }
}

class MyOrganization {
  constructor(id=null){
    this.id = id;
  }
  getOne = (id) => Request().get(`${basepath}/api/my/organizations/${id}/`);
  getAll = () => Request().get(`${basepath}/api/my/organizations/`);
  updateOne = (id, object) => Request().put(`${basepath}/api/my/organizations/${id}/`, object);
  deleteOne = (id) => Request().delete(`${basepath}/api/my/organizations/${id}/`);
  createOne = (object) => Request().post(`${basepath}/api/my/organizations/`, object);

  approveOrganization = (id) => Request().post(`${basepath}/api/my/organizations/${id}/approve/`);

  location = (id=null) => new OrgLocation(this.id, id);
  league = (id=null) => new League(this.id, id);
}

class Organization {
  constructor(id=null){
    this.id = id;
  }
  getOne = (id) => Request().get(`${basepath}/api/organizations/${id}/`);
  customQuery = (query) => Request().get(`${basepath}/api/organizations/${query}`);

  proposeOrganization = (data) => Request().post(`${basepath}/api/my/organizations/propose/`, data);

  getEvents = () => new Event().getEventsByOrganization(this.id);
}

class Event {
  constructor(id=null){
    this.id = id;
  }

  getOne = (id) => Request().get(`${basepath}/api/public/events/${id}/`);
  getAll = () => Request().get(`${basepath}/api/public/events/`);
  customQuery = (query) => Request().get(`${basepath}/api/public/events/${query}`);

  getEventsByOrganization = (orgID, queryParams) => {
    queryParams = '&' + queryParams;
    return Request().get(`${basepath}/api/public/events/?organization=${orgID}${queryParams}`)
  };
}

class User {
  constructor(id=null){
    this.id = id;
  }
  register = (email, password, first_name, last_name) => Request().post(`${basepath}/api/users/`, {email: email.toLowerCase(), password, first_name, last_name});
  login = (email, password) => Request().post(`${basepath}/api/auth/get-token/`, {email: email.toLowerCase(), password});
  changePassword = (id, data) => Request().post(`${basepath}/api/users/${id}/change_password/`, data);

  getOne = (id) => Request().get(`${basepath}/api/users/${id}/`);
  getAll = () => Request().get(`${basepath}/api/users/`);
  searchAll = (search) => Request().get(`${basepath}/api/users/?search=${search}`);
  searchExclude = (search, exclude) => Request().get(`${basepath}/api/users/?search=${search}&exclude=${exclude.join()}`);
  customQuery = (query) => Request().get(`${basepath}/api/users/${query}`);

  getProfile = (id) => Request().get(`${basepath}/api/users/${id}/profile/`);
  setProfile = (id, data) => Request().post(`${basepath}/api/users/${id}/profile/`, data);
  setProfilePhoto = (id, b64photo) => Request().post(`${basepath}/api/users/${id}/profile_photo/`, {'photo': b64photo});

  getVBProfile = (id) => Request().get(`${basepath}/api/users/${id}/vb_profile/`);
  setVBProfile = (id, data) => Request().post(`${basepath}/api/users/${id}/vb_profile/`, data);

  friends = (query='') => Request().get(`${basepath}/api/users/${this.id}/friends/${query}`);
}

export default class API {
  event = (id=null) => new Event(id);
  organization = (id=null) => new Organization(id);
  myorganization = (id=null) => new MyOrganization(id);
  user = (id=null) => new User(id);

  customGet = (uri) => Request().get(uri);
}


const API2 = {
  public: {
    auth: {
      forgotPassword: async (email) => {
        const response = await axios.post(`${basepath}/api/users/forgot_password/`, {email: email.toLowerCase()});
        return response.data;
      },
      SSOExchangeToken: async (token) => {
        const response = await axios.post(`${basepath}/api/auth/get-token-sso/`, {token});
        return response.data;
      }
    },
    user: {
      register: async (email, password, first_name, last_name) => {
        const response = await axios.post(`${basepath}/api/users/`, {email: email.toLowerCase(), password, first_name, last_name});
        return response;
      },
      validateLogin: async (token) => axios.post(`${basepath}/api/auth/verify-token/`, {token}),

      refreshToken: async (token) => {
        const response = await axios.post(`${basepath}/api/auth/refresh-token/`, {token});
        return response.data;
      },
      listUsers: async (search='', page=1) => {
        let query = `?page=${page}`;
        query += search ? `&search=${search}` : '';
        const response = await Request().get(`${basepath}/api/public/users/basic/${query}`);
        return response.data;
      },
      getUserBasic: async (userID) => {
        const response = await Request().get(`${basepath}/api/public/users/basic/${userID}/`);
        return response.data;
      },
      getUser: async (userID) => {
        const response = await Request().get(`${basepath}/api/users/${userID}/`);
        return response.data;
      },
      listUsersSubset: async (userIDs) => {
        let users = [];
        let response = await Request().get(`${basepath}/api/public/users/basic/?subset=` + userIDs.join(','));
        users.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          users.push(...response.data.results);
        }
        return users;
      },
      listUsersCustomQuery: async (query) => {
        const response = await Request().get(`${basepath}/api/users/${query}`);
        return response.data;
      },
      getFriendship: async (userID) => {
        const response = await Request().get(`${basepath}/api/users/${userID}/friendship/`);
        return response.data;
      },
      getSkillRatings: async (userID) => {
        const response = await Request().get(`${basepath}/api/users/${userID}/skill_ratings/`);
        return response.data;
      },
      setSkillRating: async (userID, skillData) => {
        const response = await Request().post(`${basepath}/api/users/${userID}/skill_ratings/`, skillData);
        return response.data;
      },
      setFriendshipRequest: async (userID) => {
        const response = await Request().post(`${basepath}/api/users/${userID}/friendship/`, {});
        return response.data;
      },
      ignoreFriendShipRequest: async (userID) => {
        const response = await Request().delete(`${basepath}/api/users/${userID}/friendship/`);
        return response.data;
      },
      endFriendship: async (userID) => {
        const response = await Request().delete(`${basepath}/api/users/${userID}/friendship/`);
        return response.data;
      },
      searchBasic: async (search, exclude=[], subset=[]) => {
        let query = `?search=${search}`;
        if(exclude) query += `&exclude=${exclude.join()}`;
        if(subset) query += `&subset=${subset.join()}`;
        const response = await Request().get(`${basepath}/api/public/users/basic/${query}`);
        return response.data;
      }
    },
    organization: {
      proposeOrganization: async (data) => {
        const response = await Request().post(`${basepath}/api/my/organizations/propose/`, data);
        return response.data;
      },
      customQuery: async (query) => {
        const response = await Request().get(`${basepath}/api/organizations/${query}`);
        return response.data;
      },
      getOrganization: async (orgID) => {
        const response = await Request().get(`${basepath}/api/organizations/${orgID}/`);
        return response.data;
      },
      listOrganizations: async (search='', page=1, exclude=[], subset=[]) => {
        let query = `?page=${page}`;
        if(search) query += `&search=${search}`;
        if(exclude.length > 0) query += `&exclude=${exclude.join()}`;
        if(subset.length > 0) query += `&subset=${subset.join()}`;
        const response = await Request().get(`${basepath}/api/organizations/${query}`);
        return response.data;
      }
    },
    award: {
      listAwards: async ({eventID, matchID, organizationID, award, userID}) => {
        let query = `?`;
        if(eventID) query += `sports_event=${eventID}&`;
        if(matchID) query += `match=${matchID}&`;
        if(organizationID) query += `organization=${organizationID}&`;
        if(award) query += `award=${award}&`;
        if(userID) query += `user=${userID}&`;
        let response = await Request().get(`${basepath}/api/public/player_awards/${query}`);
        let awards = response.data.results;
        while(response.data.next){
          response = await Request().get(response.data.next);
          awards.push(...response.data.results);
        }
        return awards;
      },
      listAwardCountsByUser: async ({eventID, matchID, organizationID, award}) => {
        let query = '?';
        if(eventID) query += `sports_event=${eventID}&`;
        if(matchID) query += `match=${matchID}&`;
        if(organizationID) query += `organization=${organizationID}&`;
        if(award) query += `award=${award}&`;
        const response = await Request().get(`${basepath}/api/public/player_awards/counts_by_player/${query}`);
        return response.data;
      }
    },
    event: {
      getEvent: async (eventID) => {
        const response = await Request().get(`${basepath}/api/public/events/${eventID}/`);
        return response.data;
      },
      getEventsCustomQuery: async (query) => {
        const response = await Request().get(`${basepath}/api/public/events/${query}`);
        return response.data;
      },
      getEventsByUser: async (userID, page=1) => {
        const response = await Request().get(`${basepath}/api/public/events/?page=${page}&ordering=-start_date&player_registrations__user=${userID}`);
        return response.data;
      },
      getEventDates: async (eventID) => {
        const response = await Request().get(`${basepath}/api/public/events/${eventID}/dates/`);
        return response.data;
      }
    },
    match: {
      getMatches: async (query={}, page=1) => {
        if(page==='all'){
          query = queryString.stringify(query);
          let matches = [];
          let response = await Request().get(`${basepath}/api/public/matches/?${query}`);
          matches.push(...response.data.results);
          while(response.data.next){
            response = await Request().get(response.data.next);
            matches.push(...response.data.results);
          }
          return matches
        }
        else {
          query.page = page;
          query = queryString.stringify(query);
          const response = await Request().get(`${basepath}/api/public/matches/?${query}`);
          return response.data
        }
      },
      getMatch: async (matchID) => {
        const response = await Request().get(`${basepath}/api/public/matches/${matchID}/`);
        return response.data;
      },
      listMatchesByUser: async (userID, page=1, {relevant, past, future, reverse}) => {
        let query = `?user=${userID}&page=${page}`;
        query += reverse ? '&ordering=-start_datetime' : '&ordering=start_datetime';
        if(future) query += `&future=True`;
        if(past) query += `&past=True`;
        if(relevant) query += '&relevant=True';
        const response = await Request().get(`${basepath}/api/public/matches/${query}`);
        return response.data;
      },
      listMatchesByReferee: async (userID, page=1, {relevant, past}) => {
        let url = `${basepath}/api/public/matches/?referee=${userID}&page=${page}`;
        if(relevant) url += '&relevant=True';
        if(past) url+= '&past=True';
        const response = await Request().get(url);
        return response.data;
      }
    },
    schedule: {
      listAllScheduleDaysByEvent: async (eventID) => {
        let schedule = [];
        let response = await Request().get(`${basepath}/api/public/day_schedules/?sports_event=${eventID}`);
        schedule.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          schedule.push(...response.data.results);
        }
        return schedule;
      }
    },
    court: {
      listAllCourtsByEvent: async (eventID) => {
        let courts = [];
        let response = await Request().get(`${basepath}/api/public/courts/?sports_event=${eventID}`);
        courts.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          courts.push(...response.data.results);
        }
        return courts;
      }
    },
    division: {
      getDivision: async (divisionID) => {
        const response = await Request().get(`${basepath}/api/public/divisions/${divisionID}/`);
        return response.data;
      },
      listAllByEvent: async (eventID) => {
        let divisions = [];
        let response = await Request().get(`${basepath}/api/public/divisions/?sports_event=${eventID}`);
        divisions.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          divisions.push(...response.data.results);
        }
        return divisions;
      },
      playerRegisterForDivision: async (divisionID) => {
        let response = await Request().post(`${basepath}/api/public/divisions/${divisionID}/registration/`, {});
        return response.data;
      },
      playerUnregisterFromDivision: async (divisionID) => {
        let response = await Request().delete(`${basepath}/api/public/divisions/${divisionID}/registration/`);
        return response.data;
      }
    },
    team_registration: {
      listAllByDivision: async (divisionID) => {
        let registrations = [];
        let response = await Request().get(`${basepath}/api/public/team_registrations/?sports_event_division=${divisionID}`);
        registrations.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          registrations.push(...response.data.results);
        }
        return registrations;
      }
    },
    user_registration: {
      listAllByDivision: async (divisionID) => {
        let registrations = [];
        let response = await Request().get(`${basepath}/api/public/user_registrations/?sports_event_division=${divisionID}`);
        registrations.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          registrations.push(...response.data.results);
        }
        return registrations;
      },
      listAllByDivisionNoTeam: async (divisionID) => {
        let registrations = [];
        let response = await Request().get(`${basepath}/api/public/user_registrations/?sports_event_division=${divisionID}&sports_event_team__isnull=True`);
        registrations.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          registrations.push(...response.data.results);
        }
        return registrations;
      },
      listByTeamIDs: async (teamIDs, search='', page=1) => {
        let query = `?page=${page}`;
        query += '&teams=' + teamIDs.join(',');
        query += search!=='' ? `&search=${search}` : '';
        let response = await Request().get(`${basepath}/api/public/user_registrations/${query}`);
        let players = response.data.results;
        while(response.data.next){
          response = await Request().get(response.data.next);
          players.push(...response.data.results);
        }
        return players
      }
    },
    venue: {
      listVenues: async (search='', page=1, onlyApproved=true, onlyPublic=true) => {
        let query = search.length > 0 ? `?search=${search}&page=${page}` : `?page=${page}`;
        query += onlyApproved ? '&approved=true' : '';
        query += onlyPublic ? '&public=true' : '';
        const response = await Request().get(`${basepath}/api/public/venues/${query}`);
        return response.data;
      },
      getVenuesSubset: async (IDs) => {
        let venues = [];
        if(IDs.length === 0){
          return venues;
        }
        const idsString = IDs.join(',');
        let response = await Request().get(`${basepath}/api/public/venues/?subset=${idsString}`);
        venues.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          venues.push(...response.data.results);
        }
        return venues;
      },
      getVenue: async (id) => {
        const response = await Request().get(`${basepath}/api/public/venues/${id}/`);
        return response.data;
      },
      getVenuesByEvent: async (eventID) => {
        const response = await Request().get(`${basepath}/api/public/venues/?sports_event=${eventID}`);
        return response.data;
      }
    }
  },
  private: {
    user: {
      login: async (email, password) => {
        const response = await axios.post(`${basepath}/api/auth/get-token/`, {email: email.toLowerCase(), password});
        return response.data;
      },
      listFriendRequests: async (userID) => {
        const response = await Request().get(`${basepath}/api/users/${userID}/friend_requests/`);
        return response.data;
      },
      getProfile: async (id) => {
        const response = await Request().get(`${basepath}/api/users/${id}/profile/`);
        return response.data;
      },
      setProfile: async (id, data) => {
        const response = await Request().post(`${basepath}/api/users/${id}/profile/`, data);
        return response.data;
      },
      setProfilePhoto: async (id, b64photo) => {
        const response = await Request().post(`${basepath}/api/users/${id}/profile_photo/`, {'photo': b64photo});
        return response.data;
      },
      getVBProfile: async (id) => {
        const response = await Request().get(`${basepath}/api/users/${id}/vb_profile/`);
        return response.data;
      },
      setVBProfile: async (id, data) => {
        const response = await Request().post(`${basepath}/api/users/${id}/vb_profile/`, data);
        return response.data;
      },
      changePassword: async (id, data) => {
        const response = await Request().post(`${basepath}/api/users/${id}/change_password/`, data);
        return response.data;
      },
      inviteUser: async (data) => {
        const response = await Request().post(`${basepath}/api/private/user_registrations/register_user_placeholder/`, data);
        return response.data;
      }
    },
    venue: {
      createVenue: async (data) => {
        const response = await Request().post(`${basepath}/api/private/venues/`, data);
        return response.data;
      },
      updateVenue: async (venueID, data) => {
        const response = await Request().put(`${basepath}/api/private/venues/${venueID}/`, data);
        return response.data;
      },
      getVenue: async (id) => {
        const response = await Request().get(`${basepath}/api/private/venues/${id}/`);
        return response.data;
      },
      getVenuesByOrg: async (orgID, page=1) => {
        const response = await Request().get(`${basepath}/api/private/venues/?organization=${orgID}&page=${page}`);
        return response.data;
      },
      deleteVenue: async (venueID) => {
        const response = await Request().delete(`${basepath}/api/private/venues/${venueID}/`);
        return response.data;
      },
      listVenues: async (search='', page=1, subset=[]) => {
        let query = `?page=${page}`;
        query += search ? `&search=${search}` : '';
        if(subset.length > 0){
          query += '&subset=' + subset.join(',');
        }
        const response = await Request().get(`${basepath}/api/private/venues/${query}`);
        return response.data;
      },
      uploadPhoto: async (venueID, photoBinary) => {
        const form = new FormData();
        form.append('file', photoBinary);
        const response = await Request().put(`${basepath}/api/private/venues/${venueID}/upload_photo/`, form);
        return response.data;
      },
      approveVenue: async (venueID) => {
        const response = await Request().post(`${basepath}/api/private/venues/${venueID}/approve/`, {});
        return response.data;
      }
    },
    game: {
      listGamesByMatch: async (matchID) => {
        let games = [];
        let response = await Request().get(`${basepath}/api/private/games/?match=${matchID}`);
        games.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          games.push(...response.data.results);
        }
        return games;
      },
      deleteGame: async (gameID) => {
        const response = await Request().delete(`${basepath}/api/private/games/${gameID}/`);
        return response.data;
      },
      createGame: async (game) => {
        const response = await Request().post(`${basepath}/api/private/games/`, game);
        return response.data;
      },
      updateGame: async (id, data) => {
        const response = await Request().put(`${basepath}/api/private/games/${id}/`, data);
        return response.data;
      }
    },
    match: {
      updateMatch: async (id, data) => {
        const response = await Request().put(`${basepath}/api/private/matches/${id}/`, data);
        return response.data;
      },
      patchMatch: async (id, data) => {
        const response = await Request().patch(`${basepath}/api/private/matches/${id}/`, data);
        return response.data;
      },
      updateStatus: async (id, status) => {
        const response = await Request().post(`${basepath}/api/private/matches/${id}/update_status/`, {status});
        return response.data;
      },
      createMatch: async (data) => {
        const response = await Request().post(`${basepath}/api/private/matches/`, data);
        return response.data;
      },
      deleteMatch: async (id) => {
        const response = await Request().delete(`${basepath}/api/private/matches/${id}/`);
        return response.data;
      },
      listMatchesByEventDate: async (eventDate) => {
        let matches = [];
        let response = await Request().get(`${basepath}/api/private/matches/?event_date=${eventDate}`);
        matches.push(...response.data.results);
        while(response.data.next){
          response = await Request().get(response.data.next);
          matches.push(...response.data.results);
        }
        return matches;
      }
    },
    award: {
      listAwards: async (page=1, {award, matchID, organizationID, eventID, userID, awardDate}) => {
        let url = `${basepath}/api/private/player_awards/?page=${page}`;
        if(award) url += `&award=${award}`;
        if(matchID) url += `&match=${matchID}`;
        if(organizationID) url += `&organization=${organizationID}`;
        if(eventID) url += `&sports_event=${eventID}`;
        if(userID) url += `&user=${userID}`;
        if(awardDate) url += `&award_date=${awardDate}`;


        const response = await Request().get(url);
        return response.data.results;
      },
      createAward: async (award) => {
        const response = await Request().post(`${basepath}/api/private/player_awards/`, award);
        return response.data;
      },
      updateAward: async (award) => {
        const response = await Request().put(`${basepath}/api/private/player_awards/${award.id}/`, award);
        return response.data;
      },
      deleteAward: async (awardID) => {
        const response = await Request().delete(`${basepath}/api/private/player_awards/${awardID}/`);
        return response.data;
      }
    },
    organization: {
      createOrganization: async (data) => {
        const response = await Request().post(`${basepath}/api/my/organizations/`, data);
        return response.data;
      },
      listMyOrganizations: async () => {
        const response = await Request().get(`${basepath}/api/my/organizations/`);
        return response.data;
      },
      listPendingOrganizations: async () => {
        const response = await Request().get(`${basepath}/api/my/organizations/pending/`);
        return response.data;
      },
      getOrganization: async (orgID) => {
        const response = await Request().get(`${basepath}/api/my/organizations/${orgID}/`);
        return response.data;
      },
      updateOrganization: async (orgID, data) => {
        const response = await Request().put(`${basepath}/api/my/organizations/${orgID}/`, data);
        return response.data;
      },
      deleteOrganization: async (orgID) => {
        const response = await Request().delete(`${basepath}/api/my/organizations/${orgID}/`);
        return response.data;
      },
      updateOrganizationLogo: async (orgID, B64Image) => {
        const response = await Request().post(`${basepath}/api/my/organizations/${orgID}/update_logo/`, {logo:B64Image});
        return response.data;
      }
    },
    league: {
      fetchLeaguesByOrg: async (orgID, page=1) => {
        const response = await Request().get(`${basepath}/api/my/organizations/${orgID}/leagues/?page=${page}`);
        return response.data;
      },
      getOne: async (orgID, leagueID) => {
        const response = await Request().get(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/`);
        return response.data;
      },
      listLeague: async (leagueID, orgID) => {
        const response = await Request().post(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/list_league/`);
        return response.data;
      },
      unlistLeague: async (leagueID, orgID) => {
        const response = await Request().post(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/unlist_league/`);
        return response.data;
      },
      deleteLeague: async (leagueID, orgID) => {
        const response = await Request().delete(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/`);
        return response.data;
      },
      updateLeague: async (leagueID, orgID, object) => {
        const response = await Request().put(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/`, object);
        return response.data;
      },
      createLeague: async (orgID, object) => {
        const response = await Request().post(`${basepath}/api/my/organizations/${orgID}/leagues/`, object);
        return response.data;
      }
    },
    team_registration: {
      registerPlayer: async (orgID, leagueID, data) => {
        const response = await Request().post(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/players/`, data);
        return response.data;
      },
      unregisterPlayer: async (orgID, leagueID, regID) => {
        const response = await Request().delete(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/players/${regID}/`);
        return response.data;
      },
      addPlayerToTeam: async (orgID, leagueID, teamID, playerRegID) => {
        const response = await Request().patch(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/players/${playerRegID}/`, {sports_event_team: teamID});
        return response.data;
      },
      removePlayerFromTeam: async (orgID, leagueID, playerRegID) => {
        const response = await Request().patch(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/players/${playerRegID}/`, {sports_event_team: null});
        return response.data;
      },
      createTeam: async (object) => {
        const response = await Request().post(`${basepath}/api/private/team_registrations/`, object);
        return response.data;
      },
      editTeam: async (teamRegID, object) => {
        const response = await Request().put(`${basepath}/api/private/team_registrations/${teamRegID}/`, object);
        return response.data;
      },
      deleteTeam: async (teamRegID) => {
        const response = await Request().delete(`${basepath}/api/private/team_registrations/${teamRegID}/`);
        return response.data;
      },
      setTeamCaptain: async (orgID, leagueID, teamRegID, userID) => {
        const response = await Request().patch(`${basepath}/api/my/organizations/${orgID}/leagues/${leagueID}/teams/${teamRegID}/`, {captain: userID});
        return response.data;
      }
    }
  },

  getFullSubset: async (listFunc, subset) => {
    let results = [];
    let response = await listFunc('', 1, subset);
    results.push(...response.data.results);
    while(response.data.next){
      response = await Request().get(response.data.next);
      results.push(...response.data.results);
    }
    return results;
  },

  logError: (error) => console.error(error),
  customGet: (uri) => Request().get(uri)
};

export { API2 }
