import axios from 'axios';

export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const IN_PROGRESS = 'IN_PROGRESS';
export const INITIAL = 'INITIAL';
export const PAGESTATE = 'PAGESTATE';

export const protectedAPI = () => {
  return axios.create({
    headers: {'Authorization': 'JWT ' + localStorage.getItem('jwt_token')}
  });
};

export const Request = () => {
  return axios.create({
    headers: localStorage.getItem('jwt_token') ? {'Authorization': 'JWT ' + localStorage.getItem('jwt_token')} : {}
  });
};

export function updateMobileFlag(val){
  return {
    type: 'core',
    mobile: val
  }
}

export function wholePageLoadEnd(redirect=null, follow=null){
  return {
    type: 'core',
    isGlobalFetching: false,
    redirect,
    follow
  }
}

export function updatePage(data){
  return {
    type: 'core',
    updatePage: data
  }
}

export function getFormErrorsFromAPIResponse(response, fields){
  const errors = {};
  for (let field of fields){
    const error = response[field];
    if(error){
      errors[field] = error;
    }
  }
  if(response.non_field_errors){
    errors._error = response.non_field_errors[0]
  }
  if(Object.keys(errors).length<1){
    errors._error = 'Unknown error occurred. Try again later.'
  }
  return errors;
}
