import { updatePage } from '../Actions/Core';
import { API2 } from '../API/API';


export const FRIEND_REQUESTS_UPDATED = 'FRIEND_REQUESTS_UPDATED';
export const FRIEND_REQUESTS_UPDATING = 'FRIEND_REQUESTS_UPDATING';
export const FRIEND_REQUESTS_ERROR_UPDATING = 'FRIEND_REQUESTS_ERROR_UPDATING';

function friendRequestsUpdated(data) {
  return {
    type: FRIEND_REQUESTS_UPDATED,
    data
  };
}

function friendRequestsUpdating() {
  return {
    type: FRIEND_REQUESTS_UPDATING
  };
}

function friendRequestsErrorUpdating() {
  return {
    type: FRIEND_REQUESTS_ERROR_UPDATING
  };
}

export function friendRequestsClear(){
  return dispatch => {
    dispatch(friendRequestsUpdated([]));
  }
}

export function friendRequestsFetch(userID){
  return dispatch => {
    dispatch(friendRequestsUpdating());
    return API2.private.user.listFriendRequests(userID)
      .then((requests) => {
        dispatch(friendRequestsUpdated(requests));
      })
      .catch((error) => {
        dispatch(friendRequestsErrorUpdating());
        if (error.response) {
          console.error(error.response);
        }
        else {
          console.error("Error: " + error);
        }
      })
  }
}

export function friendRequestAccept(fromUserID, userID){
  return dispatch => {
    dispatch(friendRequestsUpdating());
    return API2.public.user.setFriendshipRequest(fromUserID)
      .then((response) => {
        dispatch(friendRequestsFetch(userID));
        if(this.props.history.getCurrentLocation().pathname===`/users/${fromUserID}`){
          dispatch(updatePage('friendRequestAccepted'));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
        else {
          console.error("Error: " + error);
        }
      })
  }
}

export function friendRequestIgnore(fromUserID, userID){
  return dispatch => {
    dispatch(friendRequestsUpdating());
    return API2.public.user.ignoreFriendShipRequest(fromUserID)
      .then((response) => {
        dispatch(friendRequestsFetch(userID));
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
        else {
          console.error("Error: " + error);
        }
      })
  }
}
