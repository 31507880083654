import React from 'react';
import BlockUi from 'react-block-ui';
import Loader from 'react-loaders'
import PropTypes from 'prop-types';
import 'loaders.css/loaders.min.css';
import 'react-block-ui/style.css';
import '../Styles/MainStyles.css'

const BlockingLoader = ({loading, children, style}) => (
  <BlockUi tag="div" blocking={loading} loader={InlineLoader} style={{minHeight: '40px', ...style}}>
    {children}
  </BlockUi>
);

BlockingLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  style: PropTypes.object
};

BlockingLoader.defaultProps = {
  style: {}
};

const InlineLoader = () => <Loader active type="line-scale" color="#0d87e9"/>;

const PageLoader = ({isLoading, error}) => {
  // Handle the loading state
  if (isLoading) {
    return <div style={{width:"100%", height:"100%"}} className="text-center"><InlineLoader /></div>;
  }
  // Handle the error state
  else if (error) {
    return <div className="text-warning">Sorry, there was a problem loading the page.</div>;
  }
  else {
    return null;
  }
};

export { InlineLoader, PageLoader }
export default BlockingLoader
