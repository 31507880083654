export const RouteRoot = "/";
export const RouteAbout = '/about';
export const RoutePrivacyPolicy = '/privacypolicy';

export const RouteRegister = '/register';
export const RouteLogin = '/login';
export const RouteTokenLogin = '/tl';
export const RouteFBLogin = '/fb';
export const RouteForgotPassword = '/forgotpass';
export const RouteProfileEdit = '/profile';

export const RouteUsers = '/users';
export const RouteUserDetails = '/users/:userID';
export const RouteUserFriends = '/users/:userID/friends';

export const RouteOrganizations = '/organizations';
export const RouteOrganizationDetails = '/organizations/:orgID';
export const RouteMyOrganizations = '/myorganizations';
export const RouteMyOrganizationDetails = '/myorganizations/:orgID';
export const RouteEditOrganization = '/myorganizations/:orgID/edit';
export const RouteOrganizationCreate = '/organization/create';
export const RouteOrganizationPropose = '/organization/propose';
export const RouteManageLeague = '/myorganizations/:orgID/leagues/:leagueID';

export const RouteVenues = '/venues';
export const RouteVenueDetails = '/venues/:venueID';
export const RouteEditVenue = '/manage/venues/:venueID';
export const RouteCreateVenue = '/manage/venues/create';

export const RouteEvents = '/events';
export const RouteEventDetails = '/events/:eventID';

export const RouteScoreKeeper = '/scorekeeper';

export function getRoutePath(route, params=null){
  if(!params) {
    return route;
  }
  else {
    for (let p in params){
      route = route.replace(`:${p}`, params[p]);
    }
    return route;
  }
}
