import { combineReducers } from 'redux';
import user from './User';
import core from './Core';
import friendRequests from './FriendRequests';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  user,
  core,
  friendRequests,
  form: formReducer
});

export default rootReducer
