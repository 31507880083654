import React, { Component } from 'react';
import { MenuItem, Dropdown, Badge, Button, Row, Col } from 'react-bootstrap';
import playerNoPhoto from '../Static/male-volleyball-player.svg';
import { FaBell } from 'react-icons/fa';
import { withRouter } from 'react-router';
import '../Styles/MenuItemDisableHighlight.css';


const FriendRequest = ({name, imgurl, handleAccept, handleIgnore, handleClick, ...props}) => {
  return (
    <MenuItem {...props} style={{width:"250px"}} onClick={handleClick} className="menu-item-no-highlight">
      <Row>
        <Col xs={3} style={{padding:0}}>
          <img src={imgurl || playerNoPhoto} alt="" className="img-responsive"/>
        </Col>
        <Col xs={9} style={{paddingLeft:"5px", paddingRight:"5px"}}>
          <Row>
            <Col xs={12} style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{name}</Col>
            <Col xs={12}>
              <Button bsStyle="primary" bsSize="xsmall" onClick={handleAccept} style={{marginRight:"5px"}}>Accept</Button>
              <Button bsStyle="default" bsSize="xsmall" onClick={handleIgnore}>Ignore</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </MenuItem>
  )
};

class Notifications extends Component {
  constructor(props){
    super(props);
    this.state = { dropdownIsOpen: false };
    this.toggleDropdown = this.toggleDropdown.bind(this);
  };

  toggleDropdown() {
    this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })
  };

  render() {
    const {friendRequests, notifications, handleAccept, handleIgnore} = this.props;
    let frequests = [];
    for (let i = 0; i < friendRequests.requests.length; i++) {
      const fq = friendRequests.requests[i];
      const handleAcceptWrapper = (e) => {
        e.stopPropagation();
        handleAccept(fq.from_user, fq.to_user)
      };
      const handleIgnoreWrapper = (e) => {
        e.stopPropagation();
        handleIgnore(fq.from_user, fq.to_user)
      };
      const handleClick = () => {
        this.props.history.push(`/users/${fq.from_user}`);
      };
      frequests.push(<FriendRequest name={fq.from_user_name} imgurl={fq.from_user_photo}
                                    handleAccept={handleAcceptWrapper}
                                    handleIgnore={handleIgnoreWrapper} handleClick={handleClick} key={fq.from_user}/>);
    }
    if (frequests.length + notifications.length !== 0) {
      return (
        <Dropdown componentClass="li" id="notifications-dropdown">
          <Dropdown.Toggle useAnchor={true} noCaret>
            <Badge bsStyle="error"><FaBell/> {notifications.length + frequests.length}</Badge>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {frequests.length !== 0 && <MenuItem header>Friend Requests</MenuItem>}
            {frequests.length !== 0 && frequests}
            {frequests.length !== 0 && notifications.length !== 0 && <MenuItem divider/>}
            {notifications.length !== 0 && <MenuItem header>Other</MenuItem>}
            {notifications.length !== 0 && <MenuItem>Notifications Placeholder</MenuItem>}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return null
    }
  };
}

export default withRouter(Notifications);
