import React, { Component } from 'react';
import { FaFacebook, FaGooglePlusSquare, FaInstagram } from 'react-icons/fa';
import { connect } from 'react-redux';
import { userLogin, userLogout, validateLogin } from '../Actions/User';
import { friendRequestAccept, friendRequestIgnore, friendRequestsFetch,
  friendRequestsClear } from '../Actions/FriendRequests';
import { updateMobileFlag } from '../Actions/Core'
import TopMenuBar from '../Components/TopMenuBar';
import {Row, Col, Grid} from 'react-bootstrap';
import '../Styles/MainStyles.css';
import Loadable from 'react-loadable';
import { Route, Switch, Redirect } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import ProtectedRoute from '../Components/ProtectedRoute'
import { PageLoader } from '../Components/BlockingLoader';
// import packege from '../../package';

import {
  RouteRoot,
  RouteAbout,
  RouteRegister,
  RouteLogin,
  RouteFBLogin,
  RouteProfileEdit,
  RouteUsers,
  RouteUserDetails,
  RouteUserFriends,
  RouteOrganizations,
  RouteOrganizationDetails,
  RouteMyOrganizations,
  RouteEditOrganization,
  RouteOrganizationCreate,
  RouteOrganizationPropose,
  RouteMyOrganizationDetails,
  RouteManageLeague,
  RouteEventDetails,
  RouteEvents,
  RouteForgotPassword,
  RouteTokenLogin,
  RoutePrivacyPolicy,
  RouteVenues,
  RouteEditVenue,
  RouteCreateVenue,
  RouteVenueDetails,
  RouteScoreKeeper
} from '../Routes';

const AsyncHomePage = Loadable({ loader: () => import("../Containers/Home"), loading: PageLoader });
const AsyncAboutPage = Loadable({ loader: () => import("../Containers/About"), loading: PageLoader });
const AsyncRegistrationPage = Loadable({ loader: () => import("./Auth/Register"), loading: PageLoader });
const AsyncForgotPasswordPage = Loadable({ loader: () => import("./Auth/ForgotPassword"), loading: PageLoader });
const AsyncLoginPage = Loadable({ loader: () => import("./Auth/Login"), loading: PageLoader });
const AsyncTokenLoginPage = Loadable({ loader: () => import("./Auth/TokenLogin"), loading: PageLoader });
const AsyncUsersPage = Loadable({ loader: () => import("../Containers/Users"), loading: PageLoader });
const AsyncEditProfilePage = Loadable({ loader: () => import("../Containers/EditProfile"), loading: PageLoader });
const AsyncUserDetailsPage = Loadable({ loader: () => import("../Containers/UserDetails"), loading: PageLoader });
const AsyncCreateOrganizationPage = Loadable({ loader: () => import("../Containers/CreateOrganization"), loading: PageLoader });
const AsyncMyOrganizationsPage = Loadable({ loader: () => import("../Containers/MyOrganizations"), loading: PageLoader });
const AsyncOrganizationsPage = Loadable({ loader: () => import("../Containers/Organizations"), loading: PageLoader });
const AsyncEditOrganizationPage = Loadable({ loader: () => import("../Containers/EditOrganization"), loading: PageLoader });
const AsyncProposeOrganizationPage = Loadable({ loader: () => import("../Containers/ProposeOrganization"), loading: PageLoader });
const AsyncOrganizationDetailsPage = Loadable({ loader: () => import("../Containers/OrganizationDetails"), loading: PageLoader });
const AsyncMyOrganizationDetailsPage = Loadable({ loader: () => import("../Containers/MyOrganizationDetails"), loading: PageLoader });
const AsyncManageLeaguePage = Loadable({ loader: () => import("../Containers/ManageLeague"), loading: PageLoader });
const AsyncEventsPage = Loadable({ loader: () => import("../Containers/Events/Events"), loading: PageLoader });
const AsyncEventDetailsPage = Loadable({ loader: () => import("../Containers/Events/EventDetails"), loading: PageLoader });
const AsyncPrivacyPolicyPage = Loadable({ loader: () => import("../Containers/PrivacyPolicy"), loading: PageLoader });
const AsyncVenuesPage = Loadable({ loader: () => import("../Containers/Venues/Venues"), loading: PageLoader });
const AsyncEditVenuePage = Loadable({ loader: () => import("../Containers/Venues/EditVenue"), loading: PageLoader });
const AsyncCreateVenuePage = Loadable({ loader: () => import("../Containers/Venues/CreateVenue"), loading: PageLoader });
const AsyncVenueDetailsPage = Loadable({ loader: () => import("../Containers/Venues/VenueDetails"), loading: PageLoader });
const AsyncScoreKeeperPage = Loadable({ loader: () => import("../Containers/ScoreKeeper/ScoreKeeper"), loading: PageLoader });


class App extends Component {
  componentWillMount(){
    this.props.validateLogin();
    this.updateDimensions();
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.user.id && this.props.user!==nextProps.user){
      this.props.friendRequestsFetch(nextProps.user.id);
    }
    else if(this.props.user.id && !nextProps.user.id){
      this.props.friendRequestsClear();
    }
  }

  updateDimensions = () => {
    const isMobile = window.innerWidth < 1000;
    if(isMobile!==this.props.core.mobile){
      this.props.updateMobileFlag(isMobile);
    }
  };

  componentDidMount = () => {
      window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
      window.removeEventListener("resize", this.updateDimensions);
  };

  render() {
    return (
      <div style={{fontFamily: 'Lato, sans-serif', height: '100%'}}>
        <div id="wrap" style={{backgroundColor: '#f5f5f5', height: '100%'}}>
          <TopMenuBar user={this.props.user}
                      performLogin={this.props.userLogin}
                      performLogout={this.props.userLogout}
                      friendRequests={this.props.friendRequests}
                      friendRequestAccept={this.props.friendRequestAccept}
                      friendRequestIgnore={this.props.friendRequestIgnore}
                      mobile={this.props.core.mobile}
          />
          <Grid id="main">
            {/* MAIN SECTION */}
            <Row style={{paddingBottom: "10px"}}>

              {/* MAIN CONTENT */}
              <Col xs={12}>
                <Switch>
                  <Route path={RouteRoot} exact component={ AsyncHomePage } />
                  <Route path={RouteAbout} component={ AsyncAboutPage } />
                  <Route path={RoutePrivacyPolicy} component={ AsyncPrivacyPolicyPage } />

                  <Route path={RouteRegister} exact component={ AsyncRegistrationPage } />
                  <Route path={RouteLogin} exact component={ AsyncLoginPage } />
                  <Route path={RouteTokenLogin} exact component={ AsyncTokenLoginPage } />
                  <Route path={RouteForgotPassword} exact component={ AsyncForgotPasswordPage } />

                  <Route path={RouteUsers} exact component={ AsyncUsersPage } />
                  <Route path={RouteUserDetails} exact component={ AsyncUserDetailsPage } contentType="main" />
                  <Route path={RouteUserFriends} exact component={ AsyncUserDetailsPage } contentType="friends" />

                  <Route path={RouteOrganizations} exact component={ AsyncOrganizationsPage } />
                  <Route path={RouteOrganizationDetails} exact component={ AsyncOrganizationDetailsPage } />

                  <Route path={RouteEvents} exact component={ AsyncEventsPage } />
                  <Route path={RouteEventDetails} exact component={ AsyncEventDetailsPage } />

                  <Route path={RouteVenues} exact component={ AsyncVenuesPage } />
                  <Route path={RouteVenueDetails} exact component={ AsyncVenueDetailsPage } />
                  <ProtectedRoute path={RouteCreateVenue} exact component={ AsyncCreateVenuePage } />
                  <ProtectedRoute path={RouteEditVenue} exact component={ AsyncEditVenuePage } />

                  <ProtectedRoute path={RouteProfileEdit} exact component={ AsyncEditProfilePage } />

                  <ProtectedRoute path={RouteMyOrganizationDetails} exact component={ AsyncMyOrganizationDetailsPage } />
                  <ProtectedRoute path={RouteEditOrganization} exact component={ AsyncEditOrganizationPage } />
                  <ProtectedRoute path={RouteMyOrganizations} exact component={ AsyncMyOrganizationsPage } />
                  <ProtectedRoute path={RouteOrganizationCreate} exact component={ AsyncCreateOrganizationPage } />
                  <ProtectedRoute path={RouteOrganizationPropose} exact component={ AsyncProposeOrganizationPage } />
                  <ProtectedRoute path={RouteManageLeague} exact component={ AsyncManageLeaguePage } />

                  <ProtectedRoute path={RouteScoreKeeper} exact component={ AsyncScoreKeeperPage } />
                  <Redirect path="*" to="/" />
                </Switch>
              </Col>
              {/* END MAIN CONTENT */}

            </Row>
            {/* END MAIN SECTION */}
          </Grid>
        </div>
        {/* FOOTER SECTION */}
        <Grid className="footer" style={{"backgroundColor": "#ffffff"}} fluid>
          <Row>
            <Col xs={12} className="text-center">
              <h4>
                <a href="https://www.facebook.com/leaberousa"><FaFacebook/></a>
                <a href="/"><FaGooglePlusSquare/></a>
                <a href="/"><FaInstagram/></a>
                <small className="pull-right" style={{color: "white"}}>Version: {process.env.REACT_APP_VERSION || 'Unknown'}</small>
              </h4>
            </Col>
          </Row>
        </Grid>
        {/* END FOOTER SECTION */}

        <Alert stack={{limit: 3, spacing: 10}}
               effect='stackslide'
               position='top-right'
               html={true}
               beep={false}
               offset={70}
               timeout={5000}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    core: state.core,
    friendRequests: state.friendRequests
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (email, password) => {dispatch(userLogin({email, password}))},
    userLogout: () => dispatch(userLogout()),
    validateLogin: () => dispatch(validateLogin()),
    friendRequestsFetch: (userID) => dispatch(friendRequestsFetch(userID)),
    friendRequestAccept: (fromUserID, userID) => dispatch(friendRequestAccept(fromUserID, userID)),
    friendRequestIgnore: (fromUserID, userID) => dispatch(friendRequestIgnore(fromUserID, userID)),
    friendRequestsClear: () => dispatch(friendRequestsClear()),
    updateMobileFlag: (val) => dispatch(updateMobileFlag(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
