// User Reducer - responsible for dealing with store['user'] object
import { LOG_IN, LOGGED_OUT, PHOTO_UPDATED, LOGGING_IN, PROFILE_UPDATED } from '../Actions/User'
import { SUCCESS } from '../Actions/Core'
import LogRocket from "logrocket";

const defaultUserState = {
  authenticated: false,
  token: null,
  email: null,
  first_name: null,
  last_name: null,
  full_name: null,
  last_login: null,
  date_joined: null,
  id: null,
  vb_profile: null,
  photo: null,
  age: null,
  address: null,
  is_superuser: null,
  is_staff: null
};

function processLogin(state, action) {
  switch (action.status){
    case SUCCESS:
      state = Object.assign({}, state, {
        authenticated: true,
        token: action.data.token,
        email: action.data.user.email,
        first_name: action.data.user.first_name,
        last_name: action.data.user.last_name,
        full_name: action.data.user.first_name + ' ' + action.data.user.last_name,
        last_login: action.data.user.last_login,
        date_joined: action.data.user.date_joined,
        id: action.data.user.id,
        vb_profile: action.data.user.vb_profile,
        photo: action.data.user.photo,
        age: action.data.user.age,
        location: action.data.user.location,
        is_superuser: action.data.user.is_superuser,
        is_staff: action.data.user.is_staff
      });
      LogRocket.identify(`${state.email}`, {
        name: state.full_name,
        email: state.email,
        photo: state.photo,
        age: state.age,
        is_superuser: state.is_superuser,
        is_staff: state.is_staff,
      });
      return state;
    case PHOTO_UPDATED:
      state = Object.assign({}, state, {
        photo: action.photo
      });
      return state;
    case PROFILE_UPDATED:
      state = Object.assign({}, state, {
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        full_name: action.data.first_name + ' ' + action.data.last_name
      });
      return state;
    case LOGGED_OUT:
      return defaultUserState;
    case LOGGING_IN:
      return Object.assign({}, state, {
        authenticated: LOGGING_IN
      });
    default:
      return state;
  }
}

function user(state=defaultUserState, action) {
  switch (action.type) {
    case LOG_IN:
      return processLogin(state, action);
    default:
      return state;
  }
}

export default user
