export const conf = {
  fbAppID: '1921507648127476',
  fbRedirectUri: 'https://www.leabero.com/fb'
};

export const sportChoices = [
  {value:"volleyball", label:"Volleyball"},
  {value:"soccer", label:"Soccer"},
  {value:"basketball", label:"Basketball"},
  {value:"hockey", label:"Hockey"},
  {value:"tennis", label:"Tennis"}
];
let sportsMap = {};
sportChoices.forEach(s => sportsMap[s.value] = s.label);

export const daysChoices = [
  {value:"m", label:"Monday"},
  {value:"t", label:"Tuesday"},
  {value:"w", label:"Wednesday"},
  {value:"r", label:"Thursday"},
  {value:"f", label:"Friday"},
  {value:"s", label:"Saturday"},
  {value:"u", label:"Sunday"}
];

let daysMap = {};
daysChoices.forEach(d => daysMap[d.value] = d.label);

export const genderChoices = [
  {value:'m', label:'Male'},
  {value:'f', label:'Female'}
];
let gendersMap = {};
genderChoices.forEach(g => gendersMap[g.value] = g.label);

export const eventTypesMap = {
  "league": "League"
};

export const registrationTypeChoices = [
  {value:"disabled", label:"Disabled"},
  {value:"player", label:"Player"},
  {value:"team", label:"Team"},
  {value:"any", label:"Any"}
];

export const costTypeChoices = [
  {value:"team", label:"Per Team"},
  {value:"player", label:"Per Player"},
];

export const lastLoginFormatting = {
  sameDay: '[Today]',
  nextDay: '[Today]',
  nextWeek: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: '[Over a week ago]'
};

export const awardsMap = {
  mom: 'Man of the match'
};

export const matchTypesList = [
  {value: 'friendly', label: 'Friendly'},
  {value: 'pool', label: 'Pool Play'},
  {value: 'playoff', label: 'Playoff'}
];

let matchTypesMap = {};
matchTypesList.forEach(g => matchTypesMap[g.value] = g.label);

export const matchStatusList = [
  {value: 'cancelled', label: 'Cancelled'},
  {value: 'scheduled', label: 'Scheduled'},
  {value: 'in_progress', label: 'In Progress'},
  {value: 'completed', label: 'Completed'}
];

let matchStatusMap = {};
matchStatusList.forEach(g => matchStatusMap[g.value] = g.label);

export const playoffTypeDetailsList = [
  {value: 32, label: '1/32'},
  {value: 16, label: '1/16'},
  {value: 8, label: '1/8'},
  {value: 4, label: 'Quarter-final'},
  {value: 2, label: 'Semi-final'},
  {value: 1, label: 'Final'},
];

let playoffTypeDetailsMap = {};
playoffTypeDetailsList.forEach(g => playoffTypeDetailsMap[g.value] = g.label);

export const teamSourceList = [
  {value: 'team_name', label: 'Team Name'},
  {value: 'pool_seed', label: 'Pool Seed'},
  {value: 'match_winner', label: 'Match Winner'},
  {value: 'match_loser', label: 'Match Loser'},
];

export { daysMap, sportsMap, gendersMap, matchTypesMap, playoffTypeDetailsMap, matchStatusMap };

export const dateStoreFormat = 'YYYY-MM-DD';
export const timeStoreFormat = 'HH:mm:ss';
export const dateTimeStoreFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ';

export const dateDisplayFormat = 'll';
export const dateWithDayDisplayFormat = 'MMM DD, YYYY (dddd)';
export const timeDisplayFormat = 'LT';
export const dateTimeDisplayFormat = 'llll';
export const dateMonthDispayFormat = 'DD MMM';
export const dayDisplayFormat = 'dddd';
