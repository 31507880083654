import { wholePageLoadEnd, SUCCESS } from './Core'
import { getFormErrorsFromAPIResponse } from '../Actions/Core'
import { SubmissionError } from 'redux-form'
import graph from 'fb-react-sdk';
import { conf } from '../settings'
import { API2 } from '../API/API';


export const LOG_IN = 'LOG_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGING_IN = 'LOGGING_IN';
// export const REGISTRATION = 'REGISTRATION';
export const PHOTO_UPDATED = 'PHOTO_UPDATED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';

export function userLoginSuccess(data) {
  return {
    type: LOG_IN,
    status: SUCCESS,
    data
  };
}

function userLoginInProgress() {
  return {
    type: LOG_IN,
    status: LOGGING_IN
  }
}

export function userLogout(){
  localStorage.removeItem('jwt_token');
  return {
    type: LOG_IN,
    status: LOGGED_OUT
  };
}

export function photoUpdated(photo){
  return {
    type: LOG_IN,
    status: PHOTO_UPDATED,
    photo
  };
}

export function profileUpdated(data){
  return {
    type: LOG_IN,
    status: PROFILE_UPDATED,
    data
  };
}

export function validateLogin(){
  return dispatch => {
    const token = localStorage.getItem('jwt_token');
    if (token!==null){
      dispatch(userLoginInProgress());
      API2.public.user.refreshToken(token)
      .then((data) => {
        dispatch(userLoginSuccess(data));
      })
      .catch((error) => {
        dispatch(userLogout());
        API2.logError(error);
      });
    }
  }
}

export function userLogin({email, password}) {
  return dispatch => {
    email = email.toLowerCase();
    return API2.public.user.login(email, password)
      .then((response) => {
        localStorage.setItem('jwt_token', response.data.token);
        dispatch(userLoginSuccess(response.data));
      })
      .catch((error) => {
        dispatch(wholePageLoadEnd());
        if (error.response) {
          localStorage.removeItem('jwt_token');
          throw new SubmissionError(getFormErrorsFromAPIResponse(
              error.response.data,
              ['email', 'password']
            )
          );
        }
        else {
          localStorage.removeItem('jwt_token');
          console.error("Error: " + error);
          throw new SubmissionError(
            {
              _error: 'Unknown error occurred...'
            }
          );
        }
      });
  };
}
