// Friend Requests Reducer - responsible for dealing with store['friend_requests'] object
import { FRIEND_REQUESTS_ERROR_UPDATING, FRIEND_REQUESTS_UPDATED, FRIEND_REQUESTS_UPDATING } from '../Actions/FriendRequests'
import { SUCCESS, FAILURE, INITIAL, IN_PROGRESS} from '../Actions/Core'

const defaultState = {
  status: INITIAL,
  requests:[]
};

function friendRequests(state=defaultState, action) {
  switch (action.type) {
    case FRIEND_REQUESTS_UPDATING:
      return Object.assign({}, state, {
        status: IN_PROGRESS
      });
    case FRIEND_REQUESTS_UPDATED:
      return Object.assign({}, state, {
        status: SUCCESS,
        requests: action.data
      });
    case FRIEND_REQUESTS_ERROR_UPDATING:
      return Object.assign({}, state, {
        status: FAILURE
      });
    default:
      return state;
  }
}

export default friendRequests
